import { graphql } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import * as React from "react";
import Layout from "../components/Layout";
import { faChevronRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import "../css/index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";
import { AutoplayOptions, PaginationOptions } from "swiper/types";
import "../css/global.scss";

const Homepage = ({ data: { dataYaml } }: any) => {
  const {
    heroBlurb,
    featuredProject,
    projects,
    bio: {
      title: bioTitle,
      intro: bioIntro,
      description: bioDescription,
      image: {
        mobileImage: bioMobileImageSrc,
        desktopImage: bioDesktopImageSrc,
        alt: bioAlt,
      },
    },
  } = dataYaml;

  const bioMobileImage = getImage(bioMobileImageSrc);
  const bioDesktopImage = getImage(bioDesktopImageSrc);
  const bioImage = getImage(bioDesktopImageSrc);
  const [isDesktop, setIsDesktop] = useState(false);
  const swiperSpeed = 1000;
  const autoplaySettings: AutoplayOptions = {
    delay: 4000,
    pauseOnMouseEnter: false,
  };
  const paginationSettings: PaginationOptions = { clickable: true };
  const iframe = React.useRef<HTMLIFrameElement>(null);
  const embedSrc =
    "https://player.vimeo.com/video/678308391?h=0048e51039&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;muted=1&amp;autoplay=1";

  React.useEffect(() => {
    if (window.innerWidth > 991) setIsDesktop(true);

    // Here is some attempt at getting mobile collapsing working, but I can't
    // fucking figure it out. Good luck

    // let mobileDescriptions = Array.from(
    //   document.querySelectorAll(".projects .property .mobile-description")
    // );

    // mobileDescriptions.forEach(description => {
    //   description.classList.remove("collapsed");
    //   description["style"].position = "absolute";
    //   description["style"].bottom = "-50000px";
    //   description["style"].display = "initial";
    //   const { height } = getComputedStyle(description);
    //   description["style"].height = height;
    //   description["style"].position = "";
    //   description["style"].bottom = "";
    //   description["style"].display = "";
    //   description.classList.add("collapsed");
    // });
  }, []);

  React.useEffect(() => {
    if (isDesktop) {
      document
        .querySelectorAll(".projects .property .text")
        .forEach(el => (el["style"].height = `${el.clientHeight}px`));
    } else {
    }
  }, [isDesktop]);

  let propertyRows: any[][] = [];
  let properties = projects;
  switch (projects.length % 3) {
    case 0:
      break;

    case 1:
      // First two rows: 2x2
      propertyRows.push(projects.slice(0, 2));
      if (projects.length > 3) {
        propertyRows.push(projects.slice(2, 4));
      }
      properties = projects.slice(4);
      break;

    case 2:
      // First two rows: 2x3
      propertyRows.push(projects.slice(0, 2));
      properties = projects.slice(2);
      break;
  }

  for (let i = 0; i < properties.length; i += 3) {
    propertyRows.push(properties.slice(i, i + 3));
  }

  const Description = (
    name: string,
    description: string,
    imgs: any[] | any,
    alts: string[] | string
  ) => (
    <div
      className="description hidden"
      onClick={e => {
        e.stopPropagation();
        hideDescription(e.currentTarget);
      }}
    >
      <div className="container">
        <div className="content" onClick={e => e.stopPropagation()}>
          <span
            className="close"
            onClick={() => {
              const el = document.querySelector(".description:not(.hidden)");
              el && hideDescription(el);
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>

          <div className="lhs">
            <h3 className="property-name">{name}</h3>
            <div className="line"></div>
            <span className="preserve-whitespace">{description}</span>
          </div>
          {Array.isArray(imgs) ? (
            <Swiper
              id="featured-swiper"
              modules={[Autoplay, Pagination]}
              autoplay={autoplaySettings}
              speed={swiperSpeed}
              loop
              pagination={paginationSettings}
            >
              {imgs
                .filter(img => img != null)
                .map(
                  (img, i) =>
                    img && (
                      <SwiperSlide>
                        <GatsbyImage
                          className="property-image"
                          image={img}
                          alt={alts[i]}
                        />
                      </SwiperSlide>
                    )
                )}
            </Swiper>
          ) : (
            imgs && (
              <GatsbyImage
                className="property-image"
                image={imgs}
                alt={alts as string}
              />
            )
          )}
        </div>
      </div>
    </div>
  );

  const showDescription = (el: Element | null) => {
    if (!el) return;
    el.classList.remove("hidden");
  };

  const toggleMobileDescription = (el: Element | null) => {
    if (!el) return;

    if (el.classList.contains("collapsed")) {
      el.classList.remove("collapsed");
    } else {
      el.classList.add("collapsed");
    }
  };

  const hideDescription = (e: Element) => {
    const el = e as HTMLElement;
    el.classList.add("hidden");
  };

  return (
    <Layout iframe={iframe} embedSrc={embedSrc}>
      <main className="index">
        <div className="container">
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            <iframe
              src=""
              // src="https://player.vimeo.com/video/678308391?h=0048e51039&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;muted=1&amp;autoplay=1"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              title="Abbitt Goodwin Website Banner"
              ref={iframe}
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>

          <div className="hero-blurb">
            <span className="preserve-whitespace">{heroBlurb}</span>
          </div>
        </div>

        <div id="projects" className="projects">
          <div className="container">
            <h2 className="section-header">Projects</h2>
            <div className="property featured">
              <Swiper
                modules={[Pagination, Autoplay]}
                className="swiper"
                speed={swiperSpeed}
                autoplay={autoplaySettings}
                pagination={paginationSettings}
                loop
              >
                {featuredProject.images?.map(({ src, alt }, i) => {
                  const img = getImage(src);
                  return (
                    img && (
                      <SwiperSlide>
                        <GatsbyImage
                          className="images"
                          image={img}
                          alt={alt}
                          key={i}
                        />
                      </SwiperSlide>
                    )
                  );
                })}
              </Swiper>
              <div className="side-panel">
                <h3 className="property-name">{featuredProject.name}</h3>
                <div className="line"></div>
                <div className="short-description">
                  {featuredProject.shortDescription}
                </div>
                <div className="spacer"></div>
                <button
                  onClick={() => {
                    const el = document.querySelector(
                      ".property.featured .description"
                    );
                    el && showDescription(el);
                  }}
                >
                  Learn More
                </button>
                {Description(
                  featuredProject.name,
                  featuredProject.description,
                  featuredProject.images?.map(({ src }) => getImage(src)),
                  featuredProject.images?.map(({ alt }) => alt)
                )}
              </div>
            </div>

            <div className="properties">
              {propertyRows?.map((group: any[]) => {
                return group.map(
                  (
                    { name, image: { wide, half, single, alt }, description },
                    i
                  ) => {
                    let wideImg = getImage(wide);
                    let halfImg = getImage(half);
                    let singleImg = getImage(single);
                    let img: IGatsbyImageData | undefined;

                    let className = "";
                    switch (group.length) {
                      case 1:
                        className = "three";
                        img = wideImg;
                        break;

                      case 2:
                        className = "two";
                        img = halfImg;
                        break;

                      default:
                        img = singleImg;
                        break;
                    }

                    return (
                      <div
                        className={`property ${className}`}
                        key={i}
                        onClick={e => {
                          if (isDesktop) {
                            showDescription(
                              e.currentTarget.querySelector(".description")
                            );
                          } else {
                            const text = e.currentTarget.querySelector(".text");
                            if (text) {
                              const textClass = "expanded";
                              if (text.classList.contains(textClass)) {
                                text.classList.remove(textClass);
                              } else {
                                text.classList.add(textClass);
                              }
                            }

                            // TODO: Move this to a function
                            // const divider =
                            //   e.currentTarget.querySelector(".line");
                            // if (divider) {
                            //   if (divider.classList.contains("hide")) {
                            //     divider.classList.remove("hide");
                            //   } else {
                            //     divider.classList.add("hide");
                            //   }
                            // }

                            // TODO: Move this to a function
                            // const seeDetails =
                            //   e.currentTarget.querySelector(".see-details");
                            // const seeLess =
                            //   e.currentTarget.querySelector(".see-less");
                            // if (seeDetails && seeLess) {
                            //   if (seeDetails.classList.contains("hide")) {
                            //     seeDetails.classList.remove("hide");
                            //     seeLess.classList.add("hide");
                            //   } else {
                            //     seeDetails.classList.add("hide");
                            //     seeLess.classList.remove("hide");
                            //   }
                            // }

                            toggleMobileDescription(
                              e.currentTarget.querySelector(
                                ".mobile-description"
                              )
                            );
                          }
                        }}
                      >
                        <div className="image-container">
                          {img && (
                            <GatsbyImage
                              className="property-image"
                              image={img}
                              alt={alt}
                            />
                          )}
                        </div>

                        <div className="text">
                          <h3 className="property-name">{name}</h3>
                          <div className="line"></div>
                          <div className="mobile-description mobile preserve-whitespace collapsed">
                            {description}
                          </div>
                          <div className="see-details">
                            See Details&nbsp;
                            <FontAwesomeIcon icon={faChevronRight} size="xs" />
                          </div>
                          <div className="see-less">See Less</div>
                        </div>

                        {Description(name, description, img, alt)}
                      </div>
                    );
                  }
                );
              })}
            </div>
          </div>
        </div>

        <div id="about" className="bio">
          <div className="container">
            <div className="content">
              <h2 className="section-header left">{bioTitle}</h2>
              {bioDesktopImage && (
                <GatsbyImage
                  className="image desktop"
                  image={bioDesktopImage}
                  alt={bioAlt}
                />
              )}
              {bioMobileImage && (
                <GatsbyImage
                  className="image mobile"
                  image={bioMobileImage}
                  alt={bioAlt}
                />
              )}
              <div className="intro preserve-whitespace">
                <p>{bioIntro}</p>
              </div>
              <div className="description preserve-whitespace">
                {bioDescription.split("\n").map((paragraph, i) => (
                  <p key={i}>{paragraph}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Homepage;

export const query = graphql`
  query HomepageQuery {
    dataYaml {
      heroBlurb
      projects {
        name
        description
        image {
          alt
          single: src {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1.12
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
          half: src {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1.71
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
          wide: src {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 2.47
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
      }
      bio {
        title
        intro
        description
        image {
          mobileImage {
            childImageSharp {
              gatsbyImageData(width: 768)
            }
          }
          desktopImage {
            childImageSharp {
              gatsbyImageData(width: 1127)
            }
          }
          alt
        }
      }
      featuredProject {
        name
        shortDescription
        description
        images {
          alt
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
