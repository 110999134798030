import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { DefaultProps } from "../util/defaultProps";
import "../css/footer.scss";

interface FooterProps extends DefaultProps {}

const Footer = (props: FooterProps) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      dataYaml {
        footer {
          address
          email
          blurb
        }
      }
    }
  `);

  const {
    dataYaml: {
      footer: { address, email, blurb },
    },
  } = data;

  return (
    <div id="footer" className="footer">
      <div className="container">
        <img className="logo" src="/footer-logo.svg" alt="Goodwin Co. logo" />
        <p className="preserve-whitespace">{address}</p>
        <p>
          <a className="preserve-whitespace" href={`mailto:${email}`}>
            {email}
          </a>
        </p>
        <div className="blurb preserve-whitespace">{blurb}</div>
      </div>
    </div>
  );
};

export default Footer;
