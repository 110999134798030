import * as React from "react";
import { DefaultProps } from "../util/defaultProps";
import "../css/Navbar.scss";
import { useEffect } from "react";
import { useRef } from "react";
import { gsap } from "@gsap/gsap";
import { GoodwinColor } from "../util/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

interface NavbarProps extends DefaultProps {
  iframe: React.RefObject<HTMLIFrameElement>;
  embedSrc: string;
}

const Navbar = (props: NavbarProps) => {
  const { iframe, embedSrc } = props;
  const animationPlayedKey = "animation_has_played";
  const background = useRef<HTMLDivElement>(null);
  const logo = useRef<HTMLImageElement>(null);
  const animText1 = useRef<HTMLDivElement>(null);
  const animText2 = useRef<HTMLDivElement>(null);
  const animText3 = useRef<HTMLDivElement>(null);
  const nav = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);
    const forceAnim = params.get("force_anim");

    const disabled = localStorage.getItem(animationPlayedKey) === "true";
    const isMobile = window.innerWidth < 992;
    const playAnimation = (forceAnim || !disabled) && !isMobile;

    if (!logo.current) return;
    const logoBounds = logo.current.getBoundingClientRect();
    console.log("logo bounds", logoBounds);

    if (!playAnimation) {
      if (logo.current) logo.current.classList.add("ready");
      background.current && (background.current.style.display = "none");
      if (iframe?.current) iframe.current.src = embedSrc;
      return;
    }

    localStorage.setItem(animationPlayedKey, "true");
    window.scrollTo({ top: 0 });

    let bgTimeline = gsap.timeline();
    let fgTimeline = gsap.timeline();

    const timeOnScreen = 1.5;
    const delay = 0.5;
    const colors = [
      {
        bgColor: "white",
        target: animText1.current,
      },
      { bgColor: GoodwinColor.LightGreen, target: animText2.current },
      { bgColor: GoodwinColor.DarkGreen, target: animText3.current },
    ];

    colors.forEach(({ target }) => {
      if (!target) return;

      fgTimeline.set(target, {
        display: "block",
        filter: "blur(15px)",
      });
    });

    fgTimeline.set(logo.current, { x: "200vw" });
    if (logo.current) logo.current.classList.add("ready");

    colors.forEach(({ bgColor, target }, i) => {
      bgTimeline.to(background.current, {
        delay: i == 0 ? 0 : timeOnScreen + delay,
        duration: 0,
        backgroundColor: bgColor,
      });

      fgTimeline
        .to(target, {
          duration: timeOnScreen / 2,
          ease: "back.out(1)",
          left: "50%",
          transform: "translate(-50%, -50%)",
          filter: "blur(0px)",
        })
        .to(target, {
          delay: delay,
          duration: timeOnScreen / 2,
          left: "-100%",
          ease: "power4",
          filter: "blur(15px)",
        });
    });
    bgTimeline
      .to(background.current, {
        delay: timeOnScreen,
        duration: 0,
        backgroundColor: "white",
      })
      .to(background.current, { delay: 4, duration: 1, opacity: 0 })
      .to(background.current, {
        delay: 0,
        duration: 0,
        display: "none",
        onComplete: () => {
          if (iframe?.current) iframe.current.src = embedSrc;
        },
      });

    // Logo
    fgTimeline
      .set(logo.current, {
        position: "fixed",
        scale: 4,
        top: "50%",
        left: "150%",
        transform: "translateX(-50%)",
      })
      .to(logo.current, {
        duration: 1,
        scale: 4,
        top: "50%",
        left: "50%",
        transform: "translateX(-50%)",
        position: "fixed",
        ease: "back.out(1)",
      })
      .to(logo.current, {
        delay: 1,
        duration: 1,
        scale: 1,
        position: "fixed",
        top: logoBounds.top,
        left: logoBounds.left,
        transform: "translate(0)",
      })
      .to(logo.current, {
        // Keep this in sync with Navbar.scss:.navbar>.nav-container>.logo
        delay: 0,
        duration: 0,
        position: "static",
        zIndex: 20,
        transform: "translate(0)",
      });
  }, []);

  const hideNav = () => {
    if (nav?.current == null) return;

    const closeButton = document.querySelector("#close");
    if (closeButton) closeButton.classList.remove("show");
    nav.current.classList.add("hidden");
  };

  const showNav = () => {
    if (nav?.current == null) return;

    const closeButton = document.querySelector("#close");
    if (closeButton) closeButton.classList.add("show");
    nav.current.classList.remove("hidden");
  };

  return (
    <div className="navbar">
      <div className="animation-text" ref={animText1}>
        connected.
      </div>
      <div className="animation-text" ref={animText2}>
        experienced.
      </div>
      <div className="animation-text white" ref={animText3}>
        successful.
      </div>
      <div ref={background} className="background"></div>

      <div className="container">
        <div className="nav-container">
          <img ref={logo} src="/GoodwinCo_RGB.svg" className="logo" />
          <div className="menu" onClick={() => showNav()}>
            <FontAwesomeIcon icon={faBars} />
          </div>
          <div id="close" onClick={() => hideNav()}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      </div>

      <nav className="nav-items hidden" onClick={() => hideNav()} ref={nav}>
        <div className="menu-container">
          <ul onClick={e => e.stopPropagation()}>
            <li>
              <a href="#projects" onClick={() => hideNav()}>
                Projects
              </a>
            </li>
            <li>
              <a href="#about" onClick={() => hideNav()}>
                About
              </a>
            </li>
            <li>
              <a href="#footer" onClick={() => hideNav()}>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
