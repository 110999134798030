import * as React from "react";
import { DefaultProps } from "../util/defaultProps";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Helmet from "react-helmet";
import "../css/Layout.scss";

interface LayoutProps extends DefaultProps {
  iframe: React.RefObject<HTMLIFrameElement>;
  embedSrc: string;
}

const Layout = (props: LayoutProps) => {
  const { className = "", children, iframe, embedSrc } = props;
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/nvm5cfb.css" />
      </Helmet>
      <div className={`${className} layout`}>
        <Navbar iframe={iframe} embedSrc={embedSrc} />
        {children}
        <Footer />
      </div>
    </>
  );
};

export default Layout;
